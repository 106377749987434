<footer class="footer">
  <div class="container-fluid">
    <nav class="pull-left">
      <ul>
        <li>
          <a href="https://app.ebeekeeper.com"> EBeekeeper </a>
        </li>
        <li>
          <a href="https://app.ebeekeeper/about-us"> About Us </a>
        </li>
        <li>
          <a href="http://app.ebeekeeper.com"> Docs </a>
        </li>
        <li>
          <a href="https://app.ebeekeeper/license"> Licenses </a>
        </li>
      </ul>
    </nav>
    <div class="copyright pull-right">
      &copy;
      {{ test | date: "yyyy" }}, made with love by
      <a href="https://app.ebeekeeper.com" target="_blank">EBeekeeper</a>
      for a better web.
    </div>
  </div>
</footer>
