import { Component } from '@angular/core';
import { TranslateService } from './services/translate.service';
import { Languages } from './models/languages';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public subdomain: string;
  constructor(
    private translateService: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon(
      `beehive_icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icons8-beehive-30.svg',
      ),
    );

    this.matIconRegistry.addSvgIcon(
      `hive-color`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/hive-svgrepo-com-color.svg',
      ),
    );

    this.matIconRegistry.addSvgIcon(
      `hive-no-color`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/hive-svgrepo-com-no-color.svg',
      ),
    );

    this.translateService.setLanguage('rs');
  }

  getSubdomain() {
    const domain = window.location.hostname;
    if (
      domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' ||
      domain.split('.')[0] === 'lvh' ||
      domain.split('.')[0] === 'www'
    ) {
      this.subdomain = '';
    } else {
      this.subdomain = domain.split('.')[0];
    }
    if (this.subdomain === 'en' || this.subdomain === 'rs') {
    }
  }
}
