import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslateService } from 'src/app/services/translate.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public form: FormGroup;
  public langId = 'sr';
  constructor(
    public fb: FormBuilder,
    public router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public translateService: TranslateService,
    private userService: UserService,
    private notificationService: NotificationService,
  ) {
    this.matIconRegistry.addSvgIcon(
      `account-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icon-bw.svg',
      ),
    );

    this.form = this.fb.group({
      email: [, [Validators.required, Validators.email]],
      password: [
        ,
        [
          Validators.required,
          Validators.minLength(6),
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{6,}',
          ),
        ],
      ],
      rePassword: [, [Validators.required]],
      firstName: [, [Validators.required]],
      lastName: [, [Validators.required]],
      phoneNumber: [, [Validators.required]],
      homePhoneNumber: [],
      address: [],
      city: [],
      country: [],
    });

    this.form.setValidators(this.comparisonValidator());

    this.translateService.setLanguage('rs');
  }

  ngOnInit(): void {}

  public submit() {
    if (this.form.valid) {
      const user: User = this.form.value;
      this.userService.register(user).subscribe({
        next: (response: any) => {
          this.form.reset();
          this.router.navigate(['/', 'login']);
        },
        error: (err) => {
          this.notificationService.error(
            $localize`:@@register.page.notification.error.notRegister:`,
            undefined,
            undefined,
          );
        },
      });
    }
  }

  public cancel() {
    this.router.navigate(['/', 'login']);
  }

  public disableBtn() {
    return (
      this.form.controls.email.value === null ||
      this.form.controls.password.value === null ||
      this.form.controls.rePassword.value === null ||
      this.form.controls.firstName.value === null ||
      this.form.controls.lastName.value === null ||
      this.form.controls.phoneNumber.value === null
    );
  }

  public comparisonValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const password = group.controls.password;
      const rePassword = group.controls.rePassword;
      if (password.value !== rePassword.value) {
        rePassword.setErrors({ notEquivalent: true });
      } else {
        rePassword.setErrors(null);
      }
      return;
    };
  }
}
