import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { tokenGetter } from 'src/app/app.module';
import { ErrorCode } from 'src/app/helpers/error.code';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslateService } from 'src/app/services/translate.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public langId = 'sr';
  public user: SocialUser;
  public invalidLogin?: boolean;
  public api = environment.api;

  public subscription: Subscription;
  public hide = true;

  constructor(
    public router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public translateService: TranslateService,
    private authService: SocialAuthService,
    private notificationService: NotificationService,
    private userService: UserService,
    private fb: FormBuilder,
  ) {
    this.matIconRegistry.addSvgIcon(
      `account-icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/img/icon-bw.svg',
      ),
    );

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.translateService.setLanguage('rs');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.login();
  }

  public submit() {
    if (this.form.valid) {
      this.userService
        .login(this.form.get('email').value, this.form.get('password').value)
        .subscribe({
          next: (response: any) => {
            localStorage.setItem('jwt', response.authToken);
            this.router.navigate(['/dashboard']);
            this.invalidLogin = false;
          },
          error: (err) => {
            this.invalidLogin = true;
            const errorCode: ErrorCode = err.error as ErrorCode;
            if (errorCode) {
              this.notificationService.error(
                err.error,
                undefined,
                undefined,
                true,
              );
            }
          },
        });
    }
  }

  public login(facebook = false) {
    this.subscription = this.authService.authState.subscribe((user) => {
      const token = tokenGetter();

      const check =
        user !== null &&
        ((!facebook && user.provider === 'GOOGLE') ||
          (facebook && user.provider === 'FACEBOOK'));

      if (user && check) {
        this.userService.authenticate(user).subscribe({
          next: (response: any) => {
            localStorage.setItem('jwt', response.authToken);
            this.router.navigate(['/dashboard']);
            this.invalidLogin = false;
          },
          error: (err) => {
            this.invalidLogin = true;
            const errorCode: ErrorCode = err.error as ErrorCode;
            if (errorCode) {
              this.notificationService.error(
                err.error,
                undefined,
                undefined,
                true,
              );
            }
          },
        });
        this.user = user;
      } else {
        this.user = null;
      }
    });
  }

  refreshToken(): void {
    this.authService
      .refreshAuthToken(GoogleLoginProvider.PROVIDER_ID)
      .then((userData) => {});
  }

  facebookLogin() {
    this.authService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((userData) => {
        this.login(true);
      });
  }
}
