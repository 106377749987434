import { Injectable } from '@angular/core';
import { TranslateService } from './translate.service';
declare let $: any;

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  type = ['', 'info', 'success', 'warning', 'danger'];
  constructor(private translateService: TranslateService) {}

  success(msg: string, from: string, align: string, translate = false) {
    if (translate) {
      const tmsg = $localize`:@@` + msg + `:`;
      msg = tmsg;
    }
    this.message(msg, from, align, 'success');
  }

  error(msg: string, from: string, align: string, translate = false) {
    if (translate) {
      const localize = $localize;
      const id = msg;
      const translation = localize({
        // eslint-disable-next-line quote-props, @typescript-eslint/naming-convention
        '0': `:@@${id}:`,
        raw: [':'],
      } as any);
      // const tmsg = $localize`:@@UNABLE_TO_AUTHENTICATE_USER:`;
      msg = translation;
    }

    this.message(msg, from, align, 'danger');
  }

  message(msg: string, from: string, align: string, type: string) {
    if (!from) {
      from = 'bottom';
    }

    if (!align) {
      align = 'center';
    }

    $.notify(
      {
        icon: 'notifications',
        message: msg,
      },
      {
        type,
        timer: 2000,
        placement: {
          from,
          align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">' +
          '<i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" ' +
          'aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>',
      },
    );
  }
}
