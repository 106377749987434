<mat-card>
  <mat-card-title>{{
    "login.page.txt.Login" | translate: translateService.langId
  }}</mat-card-title>
  <mat-card-header>
    <mat-icon class="login-icon" svgIcon="account-icon"></mat-icon
  ></mat-card-header>
  <mat-card-content>
    <form [formGroup]="form">
      <mat-form-field>
        <input
          type="email"
          matInput
          name="email"
          placeholder="{{
            'login.page.txt.Email' | translate: translateService.langId
          }}"
          formControlName="email"
        />
        <mat-error
          *ngIf="form.invalid && form.controls['email'].hasError('required')"
          >{{
            "login.page.error.email" | translate: translateService.langId
          }}</mat-error
        >
        <mat-error
          *ngIf="form.invalid && form.controls['email'].hasError('email')"
          >{{
            "login.page.error.email.valid" | translate: translateService.langId
          }}</mat-error
        >
      </mat-form-field>

      <mat-form-field class="password">
        <input
          name="password"
          [type]="hide ? 'password' : 'text'"
          matInput
          placeholder="{{
            'login.page.txt.Password' | translate: translateService.langId
          }}"
          formControlName="password"
        />
        <mat-icon matSuffix (click)="hide = !hide">{{
          hide ? "visibility_off" : "visibility"
        }}</mat-icon>
        <mat-error
          *ngIf="form.invalid && form.controls['password'].hasError('required')"
          >{{
            "login.page.error.password" | translate: translateService.langId
          }}</mat-error
        >
      </mat-form-field>
      <div class="forgot-password-section">
        <a href="javascript:void(0);" class="forgot-password">{{
          "login.page.txt.ForgotPassword" | translate: translateService.langId
        }}</a>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div class="button">
      <button class="submit" type="submit" (click)="submit()" mat-button>
        {{ "login.page.txt.Login" | translate: translateService.langId }}
      </button>
      <p>
        <span>{{
          "login.page.txt.OrLoginWith" | translate: translateService.langId
        }}</span>
      </p>
      <button
        class="social-network-btn"
        type="submit"
        mat-button
        (click)="facebookLogin()"
      >
        <span class="fa fa-facebook"></span>
        {{ "login.page.txt.FacebookBtn" | translate: translateService.langId }}
      </button>

      <asl-google-signin-button
        class="social-network-btn2"
        type="standard"
        size="large"
        text="signup_with"
        width="300"
        >{{
          "login.page.txt.GoogleBtn" | translate: translateService.langId
        }}</asl-google-signin-button
      >
    </div>
  </mat-card-actions>
  <mat-card-footer>
    <a routerLink="/register">{{
      "login.page.txt.CreateNewAccount" | translate: translateService.langId
    }}</a>
  </mat-card-footer>
</mat-card>
