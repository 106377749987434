import { SocialUser } from '@abacritt/angularx-social-login';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  public authenticate(user: SocialUser): Observable<any> {
    return this.http.post<any>(`${environment.api}/api/User/authenticate`, {
      idToken: user.provider === 'FACEBOOK' ? user.authToken : user.idToken,
      type: user.provider === 'FACEBOOK' ? 1 : 0,
    });
  }

  public login(email: string, password: string) {
    return this.http.post<any>(`${environment.api}/api/User/login`, {
      email,
      password,
    });
  }

  public register(user: User) {
    const headers = new HttpHeaders().set(
      'Accept',
      'text/plain; charset=utf-8',
    );

    return this.http.post(`${environment.api}/api/User/register`, user, {
      responseType: 'text',
    });
  }

  public isLoggedIn() {
    return this.http.get(`${environment.api}/User/isLoggedIn`);
  }

  public profile() {
    return this.http.get<{ user: User }>(`${environment.api}/api/User/profile`);
  }

  public update(user: User) {
    const request = {
      user,
    };
    return this.http.put(`${environment.api}/api/User/update`, request);
  }

  public changePassword(request: any) {
    return this.http.put(
      `${environment.api}/api/User/change-password`,
      request,
    );
  }
}
