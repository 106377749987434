<div class="wrapper">
  <div
    class="sidebar"
    data-color="theme"
    data-background-color="white"
    data-image="./assets/img/sidebar-1.jpg"
  >
    <app-sidebar></app-sidebar>
    <div class="sidebar-background"></div>
  </div>
  <div class="main-panel">
    <app-navbar></app-navbar>
    <div class="navbar-background"></div>
    <router-outlet></router-outlet>
    <div *ngIf="isMaps('maps')">
      <app-footer></app-footer>
    </div>
  </div>
</div>
