<nav
  class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top"
>
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <a class="navbar-brand" href="javascript:void(0)">{{ getTitle() }}</a>
    </div>
    <button
      mat-raised-button
      class="navbar-toggler"
      type="button"
      (click)="sidebarToggle()"
    >
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            href="javascript:void(0)"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="material-icons">person</i>
            <p>
              <span class="d-lg-none d-md-block">Account</span>
            </p>
          </a>
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <a class="dropdown-item" href="javascript:void(0)"
              >{{ userInfo.firstName }} {{ userInfo.lastName }}</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              [routerLink]="[editProfilePath]"
              ><i class="material-icons">manage_accounts</i
              >{{
                "global.btn.EditProfile" | translate: translateService.langId
              }}</a
            >
            <a
              class="dropdown-item"
              href="javascript:void(0)"
              (click)="logout()"
              ><i class="material-icons">logout</i
              ><span>{{
                "global.btn.Logout" | translate: translateService.langId
              }}</span></a
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
