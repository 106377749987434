import { Inject, Injectable } from '@angular/core';
import { Languages } from '../models/languages';
import { BehaviorSubject } from 'rxjs';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import * as en from '../translate/messages.json';
import * as rs from '../translate/messages.rs.json';
import { loadTranslations } from '@angular/localize';

interface TranslationsFile {
  locale: string;
  translations: Record<string, string>;
}

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  public subLangId: BehaviorSubject<string> = new BehaviorSubject<string>(
    Languages.default
  );
  public langId: string = Languages.default;
  private translations: TranslationsFile = en;

  constructor(
    private dateAdapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private locale: string
  ) {}

  private get allTranslations() {
    return [...Object.entries(this.translations.translations)];
  }

  public setLanguage(langId: string): void {
    const newLangId = Languages.isValidCode(langId)
      ? langId
      : Languages.default;

    switch (newLangId) {
      case 'rs':
        this.translations = rs;
        this.locale = 'rs';
        break;
      default:
        this.translations = en;
        this.locale = 'en-US';
        break;
    }

    this.dateAdapter.setLocale(this.locale);
    loadTranslations(this.translations.translations);
    this.langId = newLangId;
    localStorage.setItem('languageCode', newLangId);
    this.subLangId.next(newLangId);
  }

  public translate(id: string): string {
    let entry: [string, string] | null = null;

    entry = this.findTranslationEntry(id) || null;

    return !entry ? '' : entry[1];
  }

  public findTranslationEntry(id: string) {
    return this.allTranslations.find((e) => e[0] === id);
  }

  public translateParameterized(id: string, args: string[]): string {
    const translation = this.translate(id);
    return this.formatString(translation, args);
  }

  public formatString(str: string, args: string[]): string {
    return str.replace(/{(\d+)}/g, (match, index) => args[index] || '');
  }
}
