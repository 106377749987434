import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from 'src/app/services/translate.service';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: $localize`:@@sidebar.item.Dashboard:`,
    icon: 'dashboard',
    class: '',
  },
  {
    path: '/calendar',
    title: $localize`:@@sidebar.item.Calendar:`,
    icon: 'calendar_month',
    class: '',
  },
  {
    path: '/apiaries',
    title: $localize`:@@sidebar.item.Apiaries:`,
    icon: 'apiary',
    class: '',
  },
  {
    path: '/hives',
    title: $localize`:@@sidebar.item.Hives:`,
    icon: 'hive',
    class: '',
  },
  {
    path: '/actions',
    title: $localize`:@@sidebar.item.Actions:`,
    icon: 'event',
    class: '',
  },
  {
    path: '/settings',
    title: $localize`:@@sidebar.item.Settings:`,
    icon: 'settings',
    class: '',
  },
  {
    path: '/teams',
    title: $localize`:@@sidebar.item.Teams:`,
    icon: 'supervisor_account',
    class: '',
  },
  {
    path: '/equipments',
    title: $localize`:@@sidebar.item.Equipment:`,
    icon: 'work',
    class: '',
  },
  {
    path: '/warehouse',
    title: $localize`:@@sidebar.item.Warehouse:`,
    icon: 'warehouse',
    class: '',
  },
  {
    path: '/reports',
    title: $localize`:@@sidebar.item.Report:`,
    icon: 'assessment',
    class: '',
  },
  {
    path: '/company',
    title: $localize`:@@sidebar.item.Company:`,
    icon: 'business',
    class: '',
  },
  {
    path: '/website',
    title: $localize`:@@sidebar.item.Website:`,
    icon: 'public',
    class: '',
  },
  {
    path: '/notifications',
    title: $localize`:@@sidebar.item.Notifications:`,
    icon: 'notifications',
    class: '',
  },
  {
    path: '/edit-profile',
    title: $localize`:@@sidebar.item.EditProfile:`,
    icon: 'notifications',
    class: 'hide',
  },
  {
    path: '/change-password',
    title: $localize`:@@sidebar.item.ChangePassword:`,
    icon: 'notifications',
    class: 'hide',
  },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  editProfilePath = '/edit-profile';
  public userInfo: any;

  constructor(
    private authService: SocialAuthService,
    private router: Router,
    public translateService: TranslateService,
    private jwtHelper: JwtHelperService,
  ) {}

  ngOnInit() {
    const token = localStorage.getItem('jwt');
    this.userInfo = this.jwtHelper.decodeToken(token);
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }

  public isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  public logout() {
    this.authService.signOut(true);
    localStorage.removeItem('jwt');
    this.router.navigate(['/login']);
  }
}
