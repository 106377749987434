import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from 'src/app/services/translate.service';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(id: string, lang: string, args: string[] | null = null): string {
    if (args == null) {
      return this.translateService.translate(id);
    } else {
      return this.translateService.translateParameterized(id, args);
    }
  }
}
