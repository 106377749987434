<mat-card>
  <mat-card-title>{{
    "register.page.txt.Register" | translate: translateService.langId
  }}</mat-card-title>
  <mat-card-header>
    <mat-icon class="login-icon" svgIcon="account-icon"></mat-icon
  ></mat-card-header>
  <mat-card-content>
    <form [formGroup]="form">
      <div class="container-fluid">
        <div class="row flex-container">
          <div class="col-md-6 flex-item">
            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="{{
                  'register.page.txt.Email' | translate: translateService.langId
                }}"
                formControlName="email"
              />
              <mat-error
                *ngIf="form.invalid && form.controls['email'].hasError('email')"
                >{{
                  "register.page.error.valid.email"
                    | translate: translateService.langId
                }}</mat-error
              >
              <mat-error
                *ngIf="
                  form.invalid && form.controls['email'].hasError('required')
                "
                >{{
                  "register.page.error.required.email"
                    | translate: translateService.langId
                }}</mat-error
              >
            </mat-form-field>

            <mat-form-field class="password">
              <input
                type="password"
                matInput
                placeholder="{{
                  'register.page.txt.Password'
                    | translate: translateService.langId
                }}"
                formControlName="password"
              />
              <mat-error
                *ngIf="
                  form.invalid && form.controls['password'].hasError('required')
                "
                >{{
                  "register.page.error.required.password"
                    | translate: translateService.langId
                }}</mat-error
              >
              <mat-error
                *ngIf="
                  form.invalid &&
                  form.controls['password'].hasError('minlength')
                "
                >{{
                  "register.page.error.minlength.password"
                    | translate: translateService.langId
                }}</mat-error
              >
              <mat-error
                *ngIf="
                  form.invalid && form.controls['password'].hasError('pattern')
                "
                >{{
                  "register.page.error.pattern.password"
                    | translate: translateService.langId
                }}</mat-error
              >
            </mat-form-field>

            <mat-form-field>
              <input
                type="password"
                matInput
                placeholder="{{
                  'register.page.txt.RePassword'
                    | translate: translateService.langId
                }}"
                formControlName="rePassword"
              />
              <mat-error
                *ngIf="
                  form.invalid &&
                  form.controls['rePassword'].hasError('required')
                "
                >{{
                  "register.page.error.required.password"
                    | translate: translateService.langId
                }}</mat-error
              >

              <mat-error
                *ngIf="
                  form.invalid &&
                  form.controls['rePassword'].hasError('notEquivalent')
                "
                >{{
                  "register.page.error.notEquivalent.password"
                    | translate: translateService.langId
                }}</mat-error
              >
            </mat-form-field>

            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="{{
                  'register.page.txt.FirstName'
                    | translate: translateService.langId
                }}"
                formControlName="firstName"
              />
              <mat-error
                *ngIf="
                  form.invalid &&
                  form.controls['firstName'].hasError('required')
                "
                >{{
                  "register.page.error.required.firstName"
                    | translate: translateService.langId
                }}</mat-error
              >
            </mat-form-field>

            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="{{
                  'register.page.txt.LastName'
                    | translate: translateService.langId
                }}"
                formControlName="lastName"
              />
              <mat-error
                *ngIf="
                  form.invalid && form.controls['lastName'].hasError('required')
                "
                >{{
                  "register.page.error.required.lastName"
                    | translate: translateService.langId
                }}</mat-error
              >
            </mat-form-field>
          </div>
          <div class="col-md-6 flex-item">
            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="{{
                  'register.page.txt.MobilePhoneNumber'
                    | translate: translateService.langId
                }}"
                formControlName="phoneNumber"
              />
              <mat-error
                *ngIf="
                  form.invalid &&
                  form.controls['phoneNumber'].hasError('required')
                "
                >{{
                  "register.page.error.required.mobilePhoneNumber"
                    | translate: translateService.langId
                }}</mat-error
              >
            </mat-form-field>

            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="{{
                  'register.page.txt.PhoneNumber'
                    | translate: translateService.langId
                }}"
                formControlName="homePhoneNumber"
              />
            </mat-form-field>

            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="{{
                  'register.page.txt.Address'
                    | translate: translateService.langId
                }}"
                formControlName="address"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="{{
                  'register.page.txt.City' | translate: translateService.langId
                }}"
                formControlName="city"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="{{
                  'register.page.txt.State' | translate: translateService.langId
                }}"
                formControlName="country"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <div class="button">
      <button
        class="submit"
        type="submit"
        (click)="submit()"
        mat-button
        [disabled]="disableBtn()"
      >
        {{
          "register.page.txt.RegisterBtn" | translate: translateService.langId
        }}
      </button>
      <button class="cancel" type="submit" (click)="cancel()" mat-button>
        {{ "register.page.txt.BackBtn" | translate: translateService.langId }}
      </button>
    </div>
  </mat-card-actions>
  <mat-card-footer> </mat-card-footer>
</mat-card>
