<div class="logo">
  <a href="/" class="simple-text">
    <div class="logo-img">
      <img src="/assets/img/icon-bw.png" />
    </div>
    EBeekeeper
  </a>
</div>
<div
  class="sidebar-wrapper"
  [ngClass]="{ 'sidebar-wrapper-mobile': isMobileMenu() }"
>
  <!-- <div *ngIf="isMobileMenu()">
    <form class="navbar-form">
      <span class="bmd-form-group">
        <div class="input-group no-border">
          <input
            type="text"
            value=""
            class="form-control"
            placeholder="Search..."
          />
          <button
            mat-raised-button
            type="submit"
            class="btn btn-white btn-round btn-just-icon"
          >
            <i class="material-icons">search</i>
            <div class="ripple-container"></div>
          </button>
        </div>
      </span>
    </form>
    <ul class="nav navbar-nav nav-mobile-menu">
      <li class="nav-item dropdown">
        <a
          class="nav-link"
          href="javascript:void(0)"
          id="navbarDropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="material-icons">person</i>
          <p>
            <span class="d-lg-none d-md-block"
              >{{ userInfo.firstName }} {{ userInfo.lastName }}</span
            >
          </p>
        </a>
        <div
          class="dropdown-menu dropdown-menu-right"
          aria-labelledby="navbarDropdownMenuLink"
        >
          <a class="dropdown-item" [routerLink]="[editProfilePath]"
            ><i class="material-icons">manage_accounts</i
            ><span>{{
              "global.btn.EditProfile" | translate: translateService.langId
            }}</span>
          </a>
          <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
            <i class="material-icons">logout</i
            ><span>{{
              "global.btn.Logout" | translate: translateService.langId
            }}</span></a
          >
        </div>
      </li>
    </ul>
  </div> -->
  <ul class="nav">
    <li
      routerLinkActive="active"
      *ngFor="let menuItem of menuItems"
      class="{{ menuItem.class }} nav-item"
    >
      <a class="nav-link" [routerLink]="[menuItem.path]">
        <i class="material-icons">{{ menuItem.icon }}</i>
        <p>{{ menuItem.title }}</p>
      </a>
    </li>
  </ul>

  <div class="fixed-profile">
    <div *ngIf="isMobileMenu()">
      <ul class="nav navbar-nav nav-mobile-menu">
        <li class="nav-item dropdown">
          <div class="profile-mobile-content">
            <div>
              <a href="javascript:void(0)" class="profile-item">
                <i class="material-icons">person</i>
                <span class="d-lg-none d-md-block"
                  >{{ userInfo.firstName }} {{ userInfo.lastName }}</span
                >
              </a>
            </div>

            <div>
              <a [routerLink]="[editProfilePath]" class="profile-item"
                ><i class="material-icons">manage_accounts</i>
                <span>{{
                  "global.btn.EditProfile" | translate: translateService.langId
                }}</span>
              </a>
            </div>
            <div>
              <a
                href="javascript:void(0)"
                (click)="logout()"
                class="profile-item"
              >
                <i class="material-icons">logout</i>
                <span>{{
                  "global.btn.Logout" | translate: translateService.langId
                }}</span>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
